import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO/SEO"
import Container from "../components/Container/Container"
import Title from "../components/Title/Title"

import FancyNav from "../sections/common/FancyNav/FancyNav"
import Location from "../sections/common/Location/Location"

const IndexPage = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicPrivacyPolicy.data

  return (
    <>
      <SEO
        title={pageData.meta_title.text}
        description={pageData.meta_description.text}
      />
      <section>
        <Container>
          <div className="mb-5 text-center md:mb-8">
            <Title singlePage>{pageData.page_title.text}</Title>
          </div>
          <div className={`flex justify-center mx-auto prose`}>
            <div dangerouslySetInnerHTML={{ __html: pageData.content.html }} />
          </div>
        </Container>
      </section>
      <section className="pb-4 md:pb-6">
        <FancyNav />
      </section>
      <section className="pb-2 md:pb-3 lg:pb-5">
        <Location />
      </section>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    prismicPrivacyPolicy {
      data {
        page_title {
          text
        }
        content {
          html
        }

        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`
